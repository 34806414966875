<template>
  <div class="milestone-item">
    <Columns customClass="mb-0 is-gapless is-multiline">
      <Column customClass="">
        <div class="mb-3 milestone-item__date">
          <!-- <span v-if="item.start_date">{{  $filters.convertDateTime(item.start_date, 'MMMM D, YYYY') }}</span> - -->
          <span
            v-if="item.node.dueDate"
            class="title is-size-6 has-text-success">
              {{  $filters.convertDateTime(item.node.dueDate, 'MMMM D, YYYY') }}
          </span>
        </div>

        <h4 class="title is-size-6 has-text-weight-medium my-1">{{item.node.title}}</h4>

      </Column>
     <!-- <Column customClass="is-gapless">
         <div class="has-text-right-desktop">
          <span class="tag is-size-7 has-text-weight-medium" :class="item.state ==='active' ? 'is-success' : 'is-warning'">{{ item.state }}</span>
        </div>
      </Column> -->
    </Columns>
    <Columns customClass="is-gapless">
      <Column>
        <div class="is-size-7">{{ item.node.description }}</div>
      </Column>
    </Columns>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  components: {
    Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
    Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
  },
  setup () {


    return {}
  }
}
</script>

<style lang="scss" scoped>
  .milestone-item {
    /* border-left: 2px solid #f5f5f5; */
    border-bottom: 1px solid #f5f5f5;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;

    overflow: hidden;

    &__date {
      position: relative;
      &::before {
        content: '';
        display: block;
        border-radius: 100%;
        width: 10px;
        height: 10px;
        background: #48c78e;
        position: absolute;
        top: 8px;
        left: -20px;
      }
    }
  }
</style>